<p-toast></p-toast>

<p-panel styleClass="m-3" *ngIf="unidadePai">
	<p-header>
		<h2 class="p-panel-title">Permissões no Sistema</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<div class="panel-opcoes">
			<!-- <p-button *ngIf="minhasUnidades.length" icon="pi pi-sitemap" [label]="selectedUnidade ? ('Unidade: '+selectedUnidade.sigla_unidade) : 'Selecione a unidade'" (onClick)="abrePainelUnidade()"></p-button> -->
			<stj-unidade-btn-select-tree (ngModelChange)="onSelectUnidade($event)"></stj-unidade-btn-select-tree>
		</div>
		<p-button styleClass="p-panel-header-icon" (click)="carrega()">
			<span class="pi pi-refresh"></span>
		</p-button>
	</ng-template>
	<ng-container #body>
		<p-table
			#tu
			[value]="items"
			[loading]="loading"
			[rows]="20"
			[paginator]="true"
			[resetPageOnSort]="true"
			[columns]="colunas">
			<ng-template pTemplate="caption">
				<label for="">Filtrar por nome: </label>
				<input title="Filtrar por nome" pInputText type="text" (input)="filtraItems(tu, colunas[1], $event)" />
			</ng-template>
			<ng-template pTemplate="header" let-columns>
				<tr>
					<th *ngFor="let col of columns" [class]="'text-'+col.align">
						{{col.header}}
					</th>
					<th class="text-center"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-rowData let-columns="columns">
				<tr>
					<td *ngFor="let col of columns" [class]="'text-'+col.align">
						<ng-container *ngIf="col.field == 'cargoTitular' || col.field == 'cargoSubstituto';else defaultField">
							<ng-container *ngIf="rowData[col.field]">
								<i class="pi pi-check color-success" [pTooltip]="rowData[col.field]"></i>
							</ng-container>
						</ng-container>
						<ng-template #defaultField>
							<div [innerHTML]="pegaValor(rowData,col.field) | sanitizeHtml"></div>
							<span class="eh_auditoria" *ngIf="col.field == 'nome' && rowData.auditor" pTooltip="Membro de unidade de auditoria"><i class="pi pi-shield"></i></span>
						</ng-template>
					</td>
					<td class="text-center">
						<p-button icon="pi pi-pencil" (onClick)="edit(rowData)"></p-button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage" let-item>
				<tr>
					<td [attr.colspan]="colunas.length + 1" class="text-center">
						Não há itens a serem exibidos.
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="footer" let-columns>
				<tr>
					<td *ngFor="let col of columns" [class]="'text-'+col.align">
						{{col.header}}
					</td>
					<td class="text-center"></td>
				</tr>
			</ng-template>

		</p-table>
	</ng-container>
</p-panel>
