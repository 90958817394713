<p-panel styleClass="m-3">
	<p-header>
		<h2 class="p-panel-title">Estrutura do Tribunal</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<button pButton class="p-panel-header-icon" (click)="carrega()">
			<span class="pi pi-refresh"></span>
		</button>
	</ng-template>
	<ng-container #body>

		<p-accordion [activeIndex]="0" styleClass="mt-3">
			<p-accordionTab header="Detalhamento das unidades">

				<p-treeTable
					#tt
					[value]="minhasUnidades"
					[columns]="colunasUnidades"
					sortMode="multiple"
					selectionMode="single"
					dataKey="sigla_unidade"
					[scrollable]="true"
					[filterMode]="'lenient'"
					[loading]="minhasUnidades.length == 0">
					<ng-template pTemplate="caption">
						<input pInputText type="text"
							(input)="filtraUnidades(tt, colunasUnidades[0], $event)" placeholder="Pesquise pela sigla da unidade" />
					</ng-template>
					<ng-template pTemplate="header" let-cols>
						<tr>
							<th class="text-left">Sigla</th>
							<th class="text-left">Nome</th>
							<th class="text-left">Titular</th>
							<th class="text-left">Substituto</th>
						</tr>
					</ng-template>
					<ng-template pTemplate="body" let-row let-item="rowData" let-cols="columns">
						<tr [ttRow]="row" [ttSelectableRow]="row">
							<td class="sigla">
								<p-treeTableToggler [rowNode]="row"></p-treeTableToggler>
								{{item.sigla_unidade}}
								<span class="eh_auditoria" *ngIf="item.auditoria" pTooltip="Unidade de auditoria"><i class="pi pi-shield"></i></span>
							</td>
							<td class="nome">{{item.nome_unidade}}</td>
							<td class="titular">{{item.titular_principal?.nome}}</td>
							<td class="titular">{{item.titular_substituto?.nome}}</td>
						</tr>
					</ng-template>
				</p-treeTable>

			</p-accordionTab>
			<p-accordionTab header="Organograma">
				<div class="card flex justify-content-left overflow-x-auto">
					<p-organizationChart [value]="items">
						<ng-template let-node pTemplate="default">
							<div class="text-center">
								<div class="font-bold sigla">{{ node.data.sigla_unidade }}</div>
								<div class="nome">{{ node.data.nome_unidade }}</div>
							</div>
						</ng-template>
					</p-organizationChart>
				</div>
			</p-accordionTab>
		</p-accordion>

	</ng-container>
</p-panel>
