import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MySharedModule } from '../../my-shared.module';

@Component({
	selector: 'tupi-edit-footer',
	standalone: true,
	imports: [
		MySharedModule,
	],
	templateUrl: './edit-footer.component.html',
	styleUrl: './edit-footer.component.scss'
})
export class EditFooterComponent implements OnInit, OnChanges
{
	constructor (
		private dialog: DynamicDialogRef,
		public dialogService: DialogService,
		private confirmationService: ConfirmationService,
	)
	{
		this.config = this.dialogService.getInstance(this.dialog).config;
	}

	config: DynamicDialogConfig;
	data?: any;
	isEditable?: boolean;
	isDeletable?: boolean;
	isEraseable?: boolean;

	ngOnInit(): void
	{
		this.data = this.config?.data;

		this.isEditable = !!this.data.capacidades?.editar;
		this.isDeletable = !!this.data.capacidades?.apagar;
		this.isEraseable = !!this.data.capacidades?.limpar;
	}
	
	ngOnChanges(changes: SimpleChanges): void
	{
		console.log(changes);
	}

	doAction(event: Event, action: string)
	{
		this.data.action = action;
		if (action == 'close' && this.data.isPristine == false)
		{
			this.confirmationService.confirm({
				target: event.target as EventTarget,
				message: 'Há alterações não salvas. Realmente deseja fechar?',
				icon: 'pi pi-question-circle',
				accept: () =>
				{
					this.dialog.close(this.data);
					// this.doAction('delete');
					// this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
				},
				reject: () =>
				{
					// this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
				}
			});
		}
		else
		{
			this.dialog.close(this.data);
		}
		// console.log(action);
	}

	confirmDelete(event: Event)
	{
		this.confirmationService.confirm({
			target: event.target as EventTarget,
			message: 'Realmente deseja excluir este item?',
			// acceptIcon: 'pi pi-trash',
			icon: 'pi pi-exclamation-triangle',
			accept: () =>
			{
				this.doAction(event,'delete');
				// this.messageService.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' });
			},
			reject: () =>
			{
				// this.messageService.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected' });
			}
		});
	}
}