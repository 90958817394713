<div class="grid">
	<div class="col-12 xl:col-5">
		<p-card [header]="usuario?.nome" [subheader]="usuario?.unidade?.nome_unidade" [style]="{'min-height': '100%'}">
			<div class="field grid">
				<div class="col md:col-2">
					<label>Matrícula:</label>
				</div>
				<div class="col">
					<span>{{usuario?.id}}</span>
				</div>
				<div class="col md:col-2">
					<label>Email:</label>
				</div>
				<div class="col">
					<span>{{usuario?.email}}</span>
				</div>
			</div>
			<div class="grid">
				<div class="col"><label>Unidades:</label></div>
			</div>
			<ng-container *ngFor="let unidade1 of usuario?.unidadesSubstituto">
				<div class="grid">
					<div class="col md:col-4 lg:col-2">
						{{unidade1?.sigla_unidade}}
					</div>
					<div class="col">
						{{unidade1?.nome_unidade}}
					</div>
				</div>
			</ng-container>
			<ng-container *ngFor="let unidade1 of usuario?.unidadesTitular">
				<div class="grid">
					<div class="col md:col-4 lg:col-2">
						{{unidade1?.sigla_unidade}}
					</div>
					<div class="col">
						{{unidade1?.nome_unidade}}
					</div>
				</div>
			</ng-container>

			<p-fieldset legend="Login manual" [toggleable]="true" [collapsed]="true">
				<p>Configure uma senha para habilitar o login manual.</p>
				<form [formGroup]="form">
					<div class="field grid">
						<label for="toggle" class="col-fixed">Habilitar login manual:</label>
						<div class="col">
							<p-toggleButton 
								id="toggle"
								formControlName="enableLogin"
								[onIcon]="'pi pi-check'" 
								offIcon="pi pi-times"
								onLabel="Habilitado"
								offLabel="Desabilitado"
								 />
						</div>
					</div>
					<div class="field grid">
						<label for="senha1" class="col-fixed">Senha:</label>
						<div class="col">
							<input type="password" id="senha1" pInputText formControlName="senha1" placeholder="Senha"
								(keyup.enter)="doPasswordReset()" />

							<div class="text-red-400 text-sm" *ngIf="senha1.dirty">
								<span class="block" [ngClass]="{'text-success': senha1.value?.match('^(?=.*[A-Z])')}">
									Ao menos uma letra maiúscula.</span>
								<span class="block" [ngClass]="{'text-success': senha1.value?.match('(?=.*[a-z])')}">
									Ao menos uma letra minúscula.</span>
								<span class="block" [ngClass]="{'text-success': senha1.value?.match('(.*[0-9].*)')}">
									Pelo menos um número.</span>
								<span class="block" [ngClass]="{'text-success': senha1.value?.match('(?=.*[!@#$%^&*])')}">
									Pelo menos um caractere especial.</span>
								<span class="block" [ngClass]="{'text-success': senha1.value?.match('.{8,}')}">
									Tamanho mínimo de 8 caracteres.</span>
							</div>
						</div>
					</div>
					<div class="field grid">
						<label for="senha2" class="col-fixed">Repita a senha:</label>
						<div class="col">
							<input type="password" id="senha2" pInputText formControlName="senha2" placeholder="Senha"
								(keyup.enter)="doPasswordReset()" />
							<div class="text-red-400 text-sm" *ngIf="senha2.dirty">
								<span class="block" [ngClass]="{'text-success': senha1.value == senha2.value}">
									As senhas devem ser iguais.</span>
							</div>
						</div>
					</div>
				</form>
			</p-fieldset>
		</p-card>
	</div>
	<div class="col-12 xl:col-7">
		<p-panel header="Permissões para o usuário">
			<p *ngIf="loading">
				<p-progressBar mode="indeterminate" [style]="{ height: '6px' }"></p-progressBar>
			</p>
			<p-pickList *ngIf="!loading" [source]="listaPermissoes" [target]="permissoesSelecionadas" [stripedRows]="true"
				[showSourceControls]="false" [showTargetControls]="false" sourceHeader="Permissões inativas"
				targetHeader="Permissões selecionadas" [responsive]="true" [dragdrop]="true"
				(onModelChange)="onChangePermissao('model',$event)"
				(onTargetReorder)="onChangePermissao('onTargetReorder',$event)"
				(onSourceReorder)="onChangePermissao('onSourceReorder',$event)" (onBlur)="onChangePermissao('onBlur',$event)"
				(onFocus)="onChangePermissao('onFocus',$event)"
				(onMoveAllToTarget)="onChangePermissao('onMoveAllToTarget',$event)"
				(onMoveAllToSource)="onChangePermissao('onMoveAllToSource',$event)"
				(onMoveToSource)="onChangePermissao('onMoveToSource',$event)"
				(onMoveToTarget)="onChangePermissao('onMoveToTarget',$event)">
				<ng-template let-item pTemplate="item">
					<div class="item" [pTooltip]="item.name">
						{{item.key}}
					</div>
					<div class="info" [pTooltip]="item.description">
						<i class="pi pi-info"></i>
					</div>

				</ng-template>
			</p-pickList>
		</p-panel>
	</div>
</div>