import { AfterContentInit, Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Unidade, Usuario } from '../../../../../types/servidor.types';
import { Subscriptions } from '../../../../../types/system.types';
import { AuthService } from '../../../../auth/services/auth.service';
import { UserModel } from '../../../../auth/services/models/user.model';
import { MySharedModule } from '../../../../shared/my-shared.module';
import { MyMessageService } from '../../../../shared/services/my-message.service';
import { PermissaoService } from '../../../services/permissao.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PrimengModule } from 'src/app/modules/shared/primeng/primeng.module';

const StrongPasswordRegx: RegExp = /^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/;

@Component({
	selector: 'stj-usuario-permissao-edit',
	standalone: true,
	imports: [
		// CommonModule,
		MySharedModule,
		PrimengModule,
	],
	providers: [
		// DialogService,
		AuthService,
		PermissaoService,
		MyMessageService,
	],
	templateUrl: './usuario-permissao-edit.component.html',
	styleUrl: './usuario-permissao-edit.component.scss'
})
export class UsuarioPermissaoEditComponent implements AfterContentInit, OnDestroy
{
	constructor(
		// private ref: DynamicDialogRef,
		private config: DynamicDialogConfig,
		private permissaoService: PermissaoService,
		private authService: AuthService,
		private msgService: MyMessageService,
	)
	{
		this.enableLogin = new FormControl(false);
		this.senha1 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.senha2 = new FormControl('', [
			Validators.required,
			Validators.minLength(8),
			Validators.pattern(StrongPasswordRegx)
		]);
		this.form = new FormGroup({
			enableLogin: this.enableLogin,
			senha1: this.senha1,
			senha2: this.senha2,
		})
	}

	unidade?: Unidade;
	usuario?: Usuario;
	currentUser?: UserModel;
	listaPermissoes: any[] = [];
	permissoesSelecionadas: any[] = [];
	permissoesUsuario: any[] = [];
	loading: boolean = true;
	subs: Subscriptions = {};
	dados: any;

	enableLogin: FormControl;
	senha1: FormControl;
	senha2: FormControl;
	form: FormGroup;

	ngAfterContentInit(): void
	{
		this.dados = this.config.data;
		this.unidade = this.config.data?.unidade;
		this.usuario = this.config.data?.usuario;

		this.enableLogin.setValue(this.usuario?.hasPassword ?? false);

		this.dados.isPristine = true;
		this.dados.isValid = true;

		this.subs['currentUser'] = this.authService.currentUser.subscribe(user =>
		{
			this.currentUser = user;
			if (user?.email) this.carrega();
		});

		this.subs['formChanges'] = this.form.valueChanges.subscribe(() =>
		{
			this.config.data.isPristine = false;
			if (this.enableLogin.value)
			{
				if (this.senha1.disabled) this.senha1.enable();
				if (this.senha2.disabled) this.senha2.enable();
				this.config.data.isValid = this.form.valid;
				if (this.form.valid)
				{
					this.config.data.password = this.senha1.value;
				}
				else
				{
					this.config.data.password = undefined;
				}
			}
			else
			{
				if (this.senha1.enabled) this.senha1.disable();
				if (this.senha2.enabled) this.senha2.disable();
				this.config.data.password = null;
				this.config.data.isValid = true;
			}
		});
	}

	ngOnDestroy(): void
	{
		for (let s in this.subs)
		{
			this.subs[s]?.unsubscribe();
		}
	}

	async carrega()
	{
		this.loading = true;

		try
		{
			this.listaPermissoes = await this.permissaoService.loadAll(this.currentUser?.email);
			this.permissoesUsuario = await this.permissaoService.loadByUser(this.usuario?.email);
			this.permissoesSelecionadas = [];
			for (let userRole of this.permissoesUsuario)
			{
				let roleId: string = userRole.roleId;
				let ind = this.listaPermissoes.findIndex(item => item.id == roleId);
				if (ind < 0) continue;
				let item = this.listaPermissoes[ind];
				this.permissoesSelecionadas.push(item);
				this.listaPermissoes.splice(ind, 1);
			}
		} catch (error)
		{
			console.log(error);
		} finally
		{
			this.loading = false;
		}
	}

	onChangePermissao(type: string, event: any)
	{
		this.config.data.isValid = true;
		this.config.data.isPristine = false;
		this.config.data.permissoes = this.permissoesSelecionadas;
	}

	async doPasswordReset()
	{
		this.msgService.clear();
		this.loading = true;
		let retorno: any = {};

		let user = this.authService.currentUser.value;
		console.log(user);

		// try
		// {
		// 	retorno = await this.authService.resetPassword(this.email.value, this.senha1.value, token);
		// 	if (retorno.status)
		// 	{
		// 		this.msgService.add({ severity: 'success', summary: retorno.msg });
		// 		setTimeout(() =>
		// 		{
		// 			this.router.navigate(['/', 'auth', 'login']);
		// 		}, 1500);
		// 	}
		// } catch (error: any)
		// {
		// 	this.msgService.error(error);
		// 	console.log(error);
		// }
		this.loading = false;
		console.log(retorno);
		return retorno;
	}
}
