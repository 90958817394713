import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Usuario } from '../../../../../types/servidor.types';
import { PrimengModule } from '../../../../shared/primeng/primeng.module';

export class Column {
	field!: string;
	header!: string;
	align?: 'left' | 'center' | 'right' = 'center';
}

@Component({
	selector: 'stj-usuario-data-grid',
	standalone: true,
	imports: [
		CommonModule,
		PrimengModule,
		FormsModule,
	],
	templateUrl: './usuario-data-grid.component.html',
	styleUrl: './usuario-data-grid.component.scss'
})
export class UsuarioDataGridComponent implements OnInit
{
	constructor() {}
	
	@Input('items') items: Usuario[] = [];
	@Input('loading') loading: boolean = false;
	@Input('lazyLoad') lazyLoad: boolean = false;
	
	colunas: Column[] = [];
	
	ngOnInit(): void
	{
		this.configuraColunas()
	}
	
	async configuraColunas()
	{
		this.colunas = [];
		this.colunas.push({
			header: 'Matrícula',
			field: 'id',
		} as Column);
		this.colunas.push({
			header: 'Nome',
			field: 'nome',
		} as Column);
		this.colunas.push({
			header: 'Email',
			field: 'email',
		} as Column);
		this.colunas.push({
			header: 'Unidade',
			field: 'unidade.sigla_unidade',
		} as Column);
		this.colunas.push({
			header: 'Cargo Efetivo',
			field: 'cargo_efetivo',
			align: 'left',
		} as Column);
		this.colunas.push({
			header: 'Cargo Comissao',
			field: 'cargo_comissao',
			align: 'left',
		} as Column);
		this.colunas.push({
			header: 'Cargo Titular',
			field: 'cargoTitular',
		} as Column);
		this.colunas.push({
			header: 'Cargo Substituto',
			field: 'cargoSubstituto',
		} as Column);
	}
	
	pegaValor(item: any, campo: string): any
	{
		let campos = campo.split('.');
		if (campos.length == 1) return item[campo];
		let res = item;
		for (let c of campos)
		{
			if (!res) return undefined;
			res = res[c];
		}
		return res;
	}
	
	edit(item: Usuario) {
		
	}
}
