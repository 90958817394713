import { Component } from '@angular/core';

@Component({
  selector: 'stj-gestor-home-page',
  standalone: true,
  imports: [],
  templateUrl: './gestor-home-page.component.html',
  styleUrl: './gestor-home-page.component.scss'
})
export class GestorHomePageComponent {

}
