<p-panel styleClass="m-3" *ngIf="unidadePai">
	<p-header>
		<h2 class="p-panel-title">Servidores da {{unidadePai.sigla_unidade ?? 'UNIDADE'}}</h2>
	</p-header>
	<ng-template pTemplate="icons">
		<button pButton class="p-panel-header-icon" (click)="carrega()">
			<span class="pi pi-refresh"></span>
		</button>
	</ng-template>
	<ng-container #body>
		<stj-usuario-data-grid [items]="items" [loading]="loading"></stj-usuario-data-grid>
	</ng-container>
</p-panel>
