import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { UsuarioDataGridComponent } from '../../../../modules/servidor/components/usuario/usuario-data-grid/usuario-data-grid.component';
import { UsuarioService } from '../../../../modules/servidor/services/usuario.service';
import { PrimengModule } from '../../../../modules/shared/primeng/primeng.module';
import { Unidade } from '../../../../types/servidor.types';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';

@Component({
	selector: 'stj-gestor-servidores',
	standalone: true,
	imports: [
		MySharedModule,
		UsuarioDataGridComponent,
	],
	providers: [
		AuthService,
		UsuarioService,
	],
	templateUrl: './gestor-servidores.component.html',
	styleUrl: './gestor-servidores.component.scss'
})
export class GestorServidoresComponent implements OnInit, OnDestroy
{
	constructor (
		// private equipeService: TeletrabalhoEquipeService,
		private authService: AuthService,
		private usuarioService: UsuarioService,
	)
	{
		
	}

	loading: boolean = false;
	items: any[] = [];
	sub?: Subscription;
	unidades: Unidade[] = [];
	unidadePai?: Unidade;

	ngOnInit(): void
	{
		this.sub = this.authService.unidades.subscribe(unidades => {
			this.unidades = unidades;
			this.unidadePai = unidades.length ? unidades[0] : undefined;
			this.carrega();
		});
	}
	
	ngOnDestroy(): void
	{
		if (this.sub) this.sub.unsubscribe();
	}

	async carrega()
	{
		this.loading = true;
		let dados = [];
		try {
			dados = await this.usuarioService.getByUnidade(this.unidadePai?.id_unidade??99999);
		} catch (error) {
			console.log(dados)
		} finally {
			this.loading = false;
		}
		this.items = dados || [];
	}
}
