<p-table
	[value]="items"
	[loading]="loading"
	[rows]="20"
	[paginator]="true"
	[columns]="colunas">

	<ng-template pTemplate="header" let-columns>
		<!-- <tr>
			<th class="text-center" [width]="100">Matrícula</th>
			<th class="text-left">Nome</th>
			<th class="text-center" [width]="100">Unidade</th>
			<th class="text-center" [width]="100">Em teletrabalho</th>
			<th class="text-center" [width]="100"></th>
		</tr> -->
		<tr>
			<th *ngFor="let col of columns" [class]="'text-'+col.align">
				{{col.header}}
			</th>
			<!-- <th class="text-center"></th> -->
		</tr>
	</ng-template>
	<ng-template pTemplate="body" let-rowData let-columns="columns">
		<tr>
			<td *ngFor="let col of columns" [class]="'text-'+col.align">
				<ng-container *ngIf="col.field == 'cargoTitular' || col.field == 'cargoSubstituto';else defaultField">
					<ng-container *ngIf="rowData[col.field]">
						<i class="pi pi-check color-success" [pTooltip]="rowData[col.field]"></i>
					</ng-container>
				</ng-container>
				<ng-template #defaultField>
					{{ pegaValor(rowData,col.field) }}
				</ng-template>
			</td>
			<!-- <td class="text-center">
				<p-button icon="pi pi-pencil" (onClick)="edit(rowData)"></p-button>
			</td> -->
		</tr>
	</ng-template>
	<!-- <ng-template pTemplate="body" let-item>
		<tr>
			<td class="text-center">{{item.id}}</td>
			<td class="text-left">{{item.nome}}</td>
			<td class="text-center">{{item.unidade?.sigla_unidade}}</td>
		</tr>
	</ng-template> -->
	<ng-template pTemplate="emptymessage" let-item>
		<tr>
			<td [attr.colspan]="colunas.length + 1" class="text-center">
				Não há itens a serem exibidos.
			</td>
		</tr>
	</ng-template>
	<ng-template pTemplate="footer" let-columns>
		<tr>
			<td *ngFor="let col of columns" [class]="'text-'+col.align">
				{{col.header}}
			</td>
			<!-- <td class="text-center"></td> -->
		</tr>
	</ng-template>

</p-table>
