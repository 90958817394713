import { Component, OnDestroy, OnInit } from '@angular/core';
import { TreeNode, TreeTableNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { Column, UsuarioDataGridComponent } from '../../../../modules/servidor/components/usuario/usuario-data-grid/usuario-data-grid.component';
import { UnidadeService } from '../../../../modules/servidor/services/unidade.service';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';
import { Unidade } from '../../../../types/servidor.types';

@Component({
	selector: 'stj-gestor-unidades',
	standalone: true,
	imports: [
		MySharedModule,
		UsuarioDataGridComponent,
	],
	providers: [
		AuthService,
		UnidadeService,
	],
	templateUrl: './gestor-unidades.component.html',
	styleUrl: './gestor-unidades.component.scss'
})
export class GestorUnidadesComponent implements OnInit, OnDestroy
{
	constructor (
		private authService: AuthService,
		private unidadeService: UnidadeService,
	)
	{
		
	}
	
	loading: boolean = false;
	items: TreeNode[] = [];
	sub?: Subscription;
	unidadesUsuario: Unidade[] = [];
	unidadePai?: Unidade;
	
	colunasUnidades: Column[] = [];
	minhasUnidades: TreeTableNode[] = [];
	
	ngOnInit(): void
	{
		this.sub = this.authService.unidades.subscribe(unidades => {
			this.unidadesUsuario = unidades;
			this.unidadePai = unidades.length ? unidades[0] : undefined;
			this.carrega();
		});
		this.configuraColunas();
	}
	
	ngOnDestroy(): void
	{
		if (this.sub) this.sub.unsubscribe();
	}

	async carrega()
	{
		this.loading = true;
		let dados:Unidade = {};
		try {
			dados = await this.unidadeService.pegaFilhasTree();
		} catch (error) {
			console.log(dados)
		} finally {
			this.loading = false;
		}
		this.items = await this.organizaItems([dados] || []);
		this.minhasUnidades = [...this.items] as TreeTableNode[];
	}
	
	async organizaItems(unidades: Unidade[]): Promise<TreeNode[]>
	{
		let items: TreeNode[] = [];
		for (let unidade of unidades)
		{
			// console.log(unidade);
			let item: TreeNode = {
				label: unidade.sigla_unidade,
				data: unidade,
				// styleClass: 'node'
				// expanded: true,
				// children: [],
			}
			if (unidade.filhos?.length) {
				item.children = await this.organizaItems(unidade.filhos);
			}
			if (item.children)
			{
				item.expanded = true;
				item.styleClass = 'node with-children';
			}
			else
			{
				item.styleClass = 'node no-children';
			}
			items.push(item);
		}
		return items;
	}
	
	async configuraColunas()
	{
		this.colunasUnidades = [
			{
				header: 'Sigla',
				field: 'sigla_unidade',
			},
			{
				header: 'Nome',
				field: 'nome_unidade',
			},
			{
				header: 'Titular',
				field: 'nome_unidade',
			},
			{
				header: 'Substituto(a)',
				field: 'nome_unidade',
			},
		]
	}

	filtraUnidades(tt: any, col: any, event: any)
	{
		tt.filter(event.currentTarget?.value, col.field, col.filterMatchMode)
	}
	
}
