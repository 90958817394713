import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { jwtDecode } from "jwt-decode";
import { Observable } from 'rxjs';
import { environment } from '../../../../environment/environment';
import { MyMessageService } from '../../shared/services/my-message.service';
import { MyCookieService } from '../services/my-cookie.service';

export const roleGuard: CanActivateFn = (route, state) =>
{
	const router = inject(Router);
	const cookieService = inject(MyCookieService);
	// const authService = inject(AuthService);
	const msgService = inject(MyMessageService);

	if (environment.DISABLE_AUTH) return true;

	return new Observable((subs) =>
	{
		const roles = route.data['roles'];
		let decoded = cookieService.getDecodedAccessToken();
		if (!decoded) 
		{
			subs.next(false);
			subs.complete();
			return;
		}
		
		let myRoles = decoded?.roles?.map((item:string) => item.toLocaleLowerCase());
		// console.log(myRoles, roles);
		// let myRoles = (authService.roles.value ?? []).map((item: MSRole) => item.value?.toLocaleLowerCase()) as string[];
		if (myRoles.length)
		{
			if (conferePermissoes(roles, myRoles))
			{
				subs.next(true);
				return;
			}
			else
			{
				setTimeout(() =>
				{
					msgService.add({ severity: 'warn', summary: 'Acesso proibido', detail: 'Desculpe, mas você não possui acesso a esta funcionalidade!' });
				}
				, 100
				);
				subs.next(router.createUrlTree(['/not-authorized']));
				subs.complete();
				return;
			}
		}
		subs.next(router.createUrlTree(['/not-authorized']));
		subs.complete();
		return;
		// authService.getMyRoles({ simples: true })
		// 	.then(myRoles =>
		// 	{
		// 		let rolesTemp: string[] = myRoles.map((item: MSRole) => item.value?.toLocaleLowerCase()) as string[];
		// 		if (conferePermissoes(roles, rolesTemp))
		// 		{
		// 			subs.next(true);
		// 		}
		// 		else
		// 		{
		// 			setTimeout(() =>
		// 			{
		// 				msgService.add({ severity: 'warn', summary: 'Acesso proibido', detail: 'Desculpe, mas você não possui acesso a esta funcionalidade!' });
		// 			}
		// 				, 100
		// 			);
		// 			subs.next(router.parseUrl('/'));
		// 		}
		// 	})
		// 	.catch((error) =>
		// 	{
		// 		subs.next(false);
		// 	})
		// 	.finally(() =>
		// 	{
		// 		subs.complete();
		// 	});
	});
};

function conferePermissoes(roles: string[], myRoles: string[]): boolean
{
	let juncao = intersect(roles, myRoles);
	return juncao.length > 0;
}

function intersect(a: string[], b: string[])
{
	var setB = new Set(b);
	return [...new Set(a)].filter(x => setB.has(x));
}
