import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MyMessageService } from '../../shared/services/my-message.service';
import { DefaultService } from '../../shared/services/-default.service';

@Injectable({
	providedIn: 'root'
})
export class UsuarioService extends DefaultService
{
	constructor (
		http: HttpClient,
		private msgService: MyMessageService,
	) {
		super(http);
	}
	
	async getByUnidade(id_unidade: number)
	{
		let url = new URL(window.location.origin + `/api-sust/intranet/usuarios/`);
		if (id_unidade)
		{
			url.searchParams.set('id_unidade',id_unidade.toString());
			url.searchParams.set('inclui_filhos','1');
		}
		
		let dados: any[] = [];
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados;
		}
	}
}
