import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { GestorHomePageComponent } from './components/gestor-home-page/gestor-home-page.component';
import { GestorPermissoesComponent } from './components/gestor-permissoes/gestor-permissoes.component';
import { GestorServidoresComponent } from './components/gestor-servidores/gestor-servidores.component';
import { GestorUnidadesComponent } from './components/gestor-unidades/gestor-unidades.component';
import { MySharedModule } from 'src/app/modules/shared/my-shared.module';
import { authGuard } from 'src/app/modules/auth/guards/auth.guard';
import { roleGuard } from 'src/app/modules/auth/guards/role.guard';
import { CustomMenuItem } from '../layout/services/menu.service';

export const moduleRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: 'home' },
	{ path: 'home', component: GestorHomePageComponent, canActivate: [authGuard, roleGuard], data: { roles: ['gestor.all'] } },
	{ path: 'unidades', component: GestorUnidadesComponent, canActivate: [authGuard, roleGuard], data: { roles: ['gestor.all'] } },
	{ path: 'servidores', component: GestorServidoresComponent, canActivate: [authGuard, roleGuard], data: { roles: ['gestor.all'] } },
	{ path: 'permissoes', component: GestorPermissoesComponent, canActivate: [authGuard, roleGuard], data: { roles: ['admin.all'] } },
];

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MySharedModule,
	]
})
export class GestorModule
{
	static menuItems: CustomMenuItem[] = [];
	static adminMenuItems: CustomMenuItem[] = [
		// ...TeletrabalhoModule.menuItems,
		{
			label: 'Permissões',
			icon: 'pi pi-fw pi-star-fill',
			// routerLink: '/gestor/servidores',
			items: [

				{
					label: 'Servidores da unidade',
					icon: 'pi pi-fw pi-users',
					routerLink: '/gestor/servidores'
				},
				{
					label: 'Ver Estrutura do Tribunal',
					icon: 'pi pi-fw pi-sitemap',
					routerLink: '/gestor/unidades'
				},
				{
					label: 'Permissões',
					icon: 'pi pi-fw pi-key',
					// badge: 'adm',
					routerLink: '/gestor/permissoes'
				},
			]
		},
	]
}
