import { Component, OnDestroy, OnInit } from '@angular/core';
import { TreeTableNode } from 'primeng/api';
import { AuthService } from '../../../../modules/auth/services/auth.service';
import { Column, UsuarioDataGridComponent } from '../../../../modules/servidor/components/usuario/usuario-data-grid/usuario-data-grid.component';
import { UnidadeService } from '../../../../modules/servidor/services/unidade.service';
import { UsuarioService } from '../../../../modules/servidor/services/usuario.service';
import { MySharedModule } from '../../../../modules/shared/my-shared.module';
import { Unidade, Usuario } from '../../../../types/servidor.types';
import { Subscriptions } from '../../../../types/system.types';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UsuarioPermissaoEditComponent } from '../../../../modules/servidor/components/permissao/usuario-permissao-edit/usuario-permissao-edit.component';
import { EditFooterComponent } from '../../../../modules/shared/components/edit-footer/edit-footer.component';
import { PermissaoService } from '../../../../modules/servidor/services/permissao.service';
import { MyMessageService } from '../../../../modules/shared/services/my-message.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UnidadeBtnSelectTreeComponent } from '../../../../modules/intranet/components/unidade-btn-select-tree/unidade-btn-select-tree.component';
import { SanitizeHtmlPipe } from 'src/app/modules/shared/sanitize-html.pipe';

@Component({
	selector: 'stj-gestor-permissoes',
	standalone: true,
	imports: [
		MySharedModule,
		UsuarioPermissaoEditComponent,
		UnidadeBtnSelectTreeComponent,
		SanitizeHtmlPipe,
	],
	providers: [
		AuthService,
		UsuarioService,
		UnidadeService,
		DialogService,
		PermissaoService,
		MyMessageService,
	],
	templateUrl: './gestor-permissoes.component.html',
	styleUrl: './gestor-permissoes.component.scss'
})
export class GestorPermissoesComponent implements OnInit, OnDestroy
{
	constructor(
		private authService: AuthService,
		private usuarioService: UsuarioService,
		// private unidadeService: UnidadeService,
		private dialogService: DialogService,
		private permissaoService: PermissaoService,
		private msgService: MyMessageService,
	)
	{

	}

	ref: DynamicDialogRef | undefined;

	loading: boolean = false;
	items: any[] = [];
	subs: Subscriptions = {};
	// unidades: Unidade[] = [];
	unidadePai?: Unidade;
	selectedUnidade?: Unidade;
	colunas: Column[] = [];

	ngOnInit(): void
	{
		this.configuraColunas();

		this.subs['unidades'] = this.authService.unidades.subscribe((unidades) =>
		{
			// this.unidades = unidades;
			this.unidadePai = unidades.length ? unidades[0] : undefined;
			// this.carregaUnidades();
		});
	}

	ngOnDestroy(): void
	{
		if (this.subs) 
		{
			for (let s in this.subs)
			{
				this.subs[s].unsubscribe();
			}
		}
	}

	async carrega()
	{
		if (!this.selectedUnidade) return;
		this.loading = true;
		let dados = [];
		try
		{
			dados = await this.usuarioService.getByUnidade(this.selectedUnidade?.id_unidade ?? 99999);
			// console.log(dados);
		} catch (error)
		{
			console.log(error)
		} finally
		{
			this.loading = false;
		}
		this.items = dados || [];
	}

	pegaValor(item: any, campo: string): any
	{
		if (campo == 'roles')
		{
			return item.roles?.map((role: any) => role.roleId).join(', ');
		}
		else if (campo == 'hasPassword')
		{
			return `<span class="${item[campo] ? 'positivo' : 'negativo'}">${item[campo] ? 'SIM' : 'NÃO'}</span>`;
		}
		let campos = campo.split('.');
		if (campos.length == 1) return item[campo];
		let res = item;
		for (let c of campos)
		{
			if (!res) return undefined;
			res = res[c];
		}
		return res;
	}

	edit(item: Usuario)
	{
		this.ref = this.dialogService.open(UsuarioPermissaoEditComponent, {
			appendTo: 'body',
			closable: true,
			maximizable: true,
			width: '70%',
			contentStyle: { overflow: 'auto' },
			header: `Editar permissões`,
			templates: {
				footer: EditFooterComponent,
			},
			data: {
				unidade: this.selectedUnidade,
				usuario: item,
				capacidades: {
					editar: true,
					apagar: false,
					limpar: false,
				}
			}
		});

		this.subs['refOnClose'] = this.ref.onClose.subscribe(async (dados) =>
		{
			if (dados?.action == 'save')
			{
				if (!dados.usuario?.email)
				{
					this.msgService.add({ severity: 'error', summary: `Não foi possível recuperar o e-mail do usuário!` });
					return;
				}
				let roles = dados.permissoes?.map((role: any) => role.id);
				// for (let perm of (res.permissoes))
				// {
				// 	let role: any = {
				// 		email: res.usuario?.email,
				// 		roleId: perm.id,
				// 	}
				// 	roles.push(role);
				// }
				try
				{
					if (roles)
					{
						let resSalvar: any = await this.permissaoService.replaceByUser(dados.usuario?.email, roles);
						if (resSalvar)
						{
							this.msgService.add({
								severity: 'success',
								summary: 'As permissões foram salvas com sucesso!',
								detail: `Adicionada(s): ${(resSalvar.salvos?.length ?? 0)},\n Removida(s): ${resSalvar.excluidos ?? 0}`,
							});
						}
					}
					if (dados.password || dados.password === null)
					{
						let resSalvar: any = await this.authService.resetPassword({email: dados.usuario.email, senha: dados.password});
						console.log(resSalvar);
						if (resSalvar && resSalvar.status == true)
						{
							this.msgService.add({
								severity: 'success',
								summary: 'A nova senha foi salva com sucesso!',
								detail: `O usuário agora pode logar diretamente com o e-mail e a senha configurada.`,
							});
						}
						else
						{
							this.msgService.add({
								severity: 'error',
								summary: 'Houve um erro ao gravar a senha!',
								detail: `${resSalvar.msg}`,
							});
						}
					}
				} catch (error: HttpErrorResponse | any)
				{
					this.msgService.add({
						severity: 'error',
						summary: 'Houve um erro ao gravar as permissões!',
						detail: `${error.status} - ${error.error ? error.error.message : error.message}`,
					});
					// console.log(error);
					setTimeout(() => this.edit(item), 500);
				} finally
				{
					this.carrega();
				}
			}
			else
			{
				// console.log(dados?.action);
			}
		})
	}

	async configuraColunas()
	{

		this.colunas = [];
		this.colunas.push({
			header: 'Matrícula',
			field: 'id',
		} as Column);
		this.colunas.push({
			header: 'Nome',
			field: 'nome',
		} as Column);
		// this.colunas.push({
		// 	header: 'Email',
		// 	field: 'email',
		// } as Column);
		this.colunas.push({
			header: 'Unidade',
			field: 'unidade.sigla_unidade',
		} as Column);
		this.colunas.push({
			header: 'Cargo Titular',
			field: 'cargoTitular',
		} as Column);
		this.colunas.push({
			header: 'Cargo Substituto',
			field: 'cargoSubstituto',
		} as Column);
		this.colunas.push({
			header: 'Possui login manual',
			field: 'hasPassword',
		} as Column);
		this.colunas.push({
			header: 'Permissões',
			field: 'roles',
		} as Column);

	}

	filtraItems(tt: any, col: any, event: any)
	{
		tt.filter(event.currentTarget?.value, col.field, col.filterMatchMode)
	}

	onSelectUnidade(unidade: Unidade)
	{
		// this.sidebarVisible = false;
		// row.node.selected = true;
		// this.selectedUnidadeTree = row?.node;
		this.selectedUnidade = unidade;
		this.carrega();
	}
}
