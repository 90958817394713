import { Injectable } from '@angular/core';
import { Permissao } from '../../../types/servidor.types';
import { DefaultService } from '../../shared/services/-default.service';
import { HttpClient } from '@angular/common/http';
import { MyMessageService } from '../../shared/services/my-message.service';

@Injectable({
	providedIn: 'root'
})
export class PermissaoService extends DefaultService
{
	constructor (
		http: HttpClient,
		private msgService: MyMessageService,
	)
	{
		super(http);
	}
	
	async loadAll(email?: string):Promise<Permissao[]> {
		let dados: Permissao[] = [];
		let url = new URL(window.location.origin + `/api-sust/auth/roles/all`);
		if (email)
		{
			url.searchParams.set('email',email!);
		}
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados as Permissao[];
		}
	}
	
	async loadByUser(email?: string):Promise<Permissao[]>
	{
		let dados: Permissao[] = [];
		let url = new URL(window.location.origin + `/api-sust/auth/roles/user`);
		if (email)
		{
			url.searchParams.set('email',email!);
		}
		try {
			dados = await this._genericGet(url.href);
		} catch (error: any) {
			this.msgService.add({severity: 'error',summary: 'Erro ao carregar os dados!', detail: `${error.error.statusCode} - ${error.error.message}`})
			// console.log(error);
		} finally {
			return dados as Permissao[];
		}
	}
	
	async replaceByUser(email: string, roles: any[])
	{
		return new Promise((resolve, reject) =>
		{
			let url = new URL(window.location.origin + `/api-sust/auth/roles/replace`);
			if (email)
			{
				url.searchParams.set('email',email!);
			}
			this.http.post(url.href, {roles}, { withCredentials: true })
			.subscribe({
				next(value)
				{
					resolve(value);
				},
				error(err)
				{
					reject(err);
				},
			})
			
		});
	}
}
